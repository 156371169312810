
import { defineComponent, getCurrentInstance, onMounted, ref, computed } from "vue";
import { Field, ErrorMessage, FieldArray, useForm } from "vee-validate";
import { TypeReseauEnum } from "@/store/enums/TypeReseauEnum";
import { useFunctions } from "@/composables/useFunctions";

export default defineComponent({
  name: "FilterDeclarationsModal",
  props: {
    // permissionnaireId: { String, required: true}
  },
  components: {
    Field,
    ErrorMessage,
    FieldArray ,
  },
  setup(props) {

    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;
    const vfm = getCurrentInstance()?.proxy?.$vfm;
    let typesReseau = TypeReseauEnum;    

    const title = ref("Filtrer les déclarations");
    const showModal = ref(false);
    let filterOptions = ref<any>({
      exercice: null,
      searchType: null,
      searchInput: ''
    });
    const {listYears} = useFunctions();

    const years = computed(() => {
      return listYears();
    });

    const initFilterOptions = () => {
      filterOptions.value = {
        exercice: null,
        searchType: null,
        searchInput: ''
      };
    }


    const closeModal = () => {
      showModal.value = false;
      dismissModal();
    }

    const dismissModal = () => {
      showModal.value = false;
    }

    onMounted(() => {
      emitter.on('open-filter-options', (data) => {
        filterOptions.value = {...data.filterOptions};
        vfm?.show('filterOptionsModal');
      });
    })

    const submit = () => {
      emitter.emit('apply-filter-declarations', filterOptions.value);
      vfm?.hide('filterOptionsModal');
    }

    const resetFilter = () => {
      initFilterOptions();
      emitter.emit('apply-filter-declarations', filterOptions.value);
      vfm?.hide('filterOptionsModal');
    }

    return {
      resetFilter,
      dismissModal,
      closeModal,
      submit,
      title,
      showModal,
      filterOptions,
      typesReseau,
      years
      
    };
  }
});
