import { StatutDeclarationEnum } from "@/core/models/enum/StatutDeclarationEnum";

//Plugin pour fonctions sur les nombres
export function  useDeclaration () {
  
  const formatDeclarationStatus = (statut: string) => {
    let statutEnum = StatutDeclarationEnum;

    switch(statut){
      case statutEnum.NOUVEAU:
        return "En attente";
      case statutEnum.VALIDER:
        return "Validé";
      case statutEnum.A_MODIFIER:
        return "A modifier";
      default:
        return "Indéfini";
    }
  }

  return {
    formatDeclarationStatus
  }
}